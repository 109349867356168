import { Component, OnInit } from '@angular/core';
import { BreakpointsService } from 'src/core-modules/breakpoints/breakpoints.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LayoutService } from '../layout.service';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { QrCodeDrawerComponent } from 'src/app/private/drawers/qr-code-drawer/qr-code-drawer.component';
import { UserMenuDrawerComponent } from '../drawers/user-menu-drawer/user-menu-drawer.component';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, hamburgerAnimation } from 'purple-lib';
import { ChangeLanguageDrawerComponent } from '../drawers/change-language-drawer/change-language-drawer.component';
import { GetUserMembershipQrCodeResponse, MembershipManagerService } from 'src/core-modules/sdk/api';
import { EnvironmentService } from 'src/core-modules/environment/environment-service';

@Component({
  selector: 'app-purple-sales-header',
  templateUrl: './purple-sales-header.component.html',
  styleUrls: ['./purple-sales-header.component.scss'],
  animations: [hamburgerAnimation]
})
export class PurpleSalesHeaderComponent implements OnInit {
  menuOpen: boolean = false;
  mobileMenuClass = 'close';
  qrCodeOpen: boolean = false;
  userMenuDrawer: NzDrawerRef<UserMenuDrawerComponent, string> | undefined;
  hexColor: string = "#fff";
  membershipInfo: GetUserMembershipQrCodeResponse | undefined;

  constructor(public breakSvc: BreakpointsService, public tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    public layoutSvc: LayoutService, public authSvc: AuthenticationService, private drawerSvc: NzDrawerService, private memberSvc: MembershipManagerService, public env: EnvironmentService) { }

  async ngOnInit() {
    if(this.authSvc.isLoggedIn){
      this.reloadMembership();
    }    
  }

  async reloadMembership(){
    await this.apiProxySvc.makeRequestErrorFunction<GetUserMembershipQrCodeResponse>(()=>this.memberSvc.getUserMembershipQrCode(this.tsvc.currentLanguage.value),true, "internal-loader",500,
      (res: PurpleApiMakeRequestResponse<GetUserMembershipQrCodeResponse>)=>{
        this.membershipInfo = {
          hasMembership: false,
          hexColor: "#fff"
        };
      },(res: PurpleApiMakeRequestResponse<GetUserMembershipQrCodeResponse>)=>{
        this.membershipInfo = res.data!;
      })
  }

  hamburgerClick() {
    if (this.menuOpen) {
      this.mobileMenuClass = 'close'
      setTimeout(() => {
        this.menuOpen = !this.menuOpen;
      }, 300);
    } else {
      this.menuOpen = !this.menuOpen;
      setTimeout(() => {
        this.mobileMenuClass = 'open'
      }, 300);
    } 
  }

  async mobileNavigate() {
    this.menuOpen = false;
  }

  showQrCodeDrawer() {
    this.layoutSvc.qrCodeOpen.next(true);
    this.drawerSvc.create<QrCodeDrawerComponent, { value: string }, string>({
      nzTitle: undefined,
      nzContent: QrCodeDrawerComponent,
      nzClosable: false,
      nzWrapClassName: "qr-code",
      nzFooter: undefined,
      nzPlacement: 'bottom',
    }).afterClose.subscribe(() => {
      this.layoutSvc.qrCodeOpen.next(false);
    })
  }

  showUserMenuDrawer() {
    this.userMenuDrawer = this.drawerSvc.create<UserMenuDrawerComponent, { value: string }, string>({
      nzTitle: undefined,
      nzContent: UserMenuDrawerComponent,
      nzClosable: true,
      nzWrapClassName: "user-menu-drawer",
      nzFooter: undefined,
      nzHeight: "260px",
      nzContentParams: {
        logout: () => this.layoutSvc.logout(this.userMenuDrawer),
      },
      nzPlacement: 'bottom'
    });
  }

  openChangeLanguageDrawer() {
    this.drawerSvc.create<ChangeLanguageDrawerComponent, { value: string }, boolean>({
      nzTitle: this.tranPipe.transform("drawer_change_language_title", "Cambia lingua"),
      nzContent: ChangeLanguageDrawerComponent,
      nzWrapClassName: "change-language-drawer",
      nzFooter: undefined,
      nzPlacement: 'bottom',
      nzHeight: "130px",
    })
  }

  openMenu(){
    if(this.membershipInfo == undefined && this.authSvc.isLoggedIn){
        this.reloadMembership();    
    }
  }

}
